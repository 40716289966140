<template>
  <div class="projectConfigList-wrapper">
    <list
      ref="list"
      exportPermission
      :searchUrl="searchUrl"
      :multiExport="multiExport"
      :searchParams.sync="searchParams"
      :headers="headers"
      :handleData="handleData"
    >
      <template #headerSlot>
        <el-dropdown trigger="click" @command="importClick" v-if="importImproveData">
          <v-button>
            导入扩展数据<i class="el-icon-arrow-down el-icon--right"></i>
          </v-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in multiImport" :key="index" :command="item">
              <v-button  type="text" :text="item.text"></v-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <v-button v-if="userType !== '101'" text="导入扩展数据" permission="importImproveData" @click="goPageImproveDataImport"></v-button> -->
        <v-button v-if="userType !== '101'" text="导入项目" permission="import" @click="goPageImport"></v-button>
        <v-button v-if="userType !== '101'" text="新增项目" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="项目名称" v-model="searchParams.communityName"></v-input>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
        <v-input label="所在省" v-model="searchParams.province"></v-input>
        <v-input label="所在城市" v-model="searchParams.city"></v-input>
        <v-input label="所在区" v-model="searchParams.area"></v-input>
        <v-input label="所在街道" v-model="searchParams.street"></v-input>
        <v-input label="所在社区" v-model="searchParams.shequ"></v-input>
        <v-select label="项目阶段状态" v-model="searchParams.communityStage" :options="communityStageOps"></v-select>
        <v-select label="项目类型" v-model="searchParams.communityType" :options="communityTypeOps"></v-select>
        <v-select label="项目状态" v-model="searchParams.communityStatus" :options="communityStatusOps"></v-select>
        <!-- <v-select label="管理属性" v-model="searchParams.manageAttribute" :options="manageAttributeOps"></v-select>
        <v-select label="业务属性" v-model="searchParams.serviceAttribute" :options="serviceAttributeOps"></v-select> -->
        <v-select label="该项目是否用于测试或展示" v-model="searchParams.virtual" :options="virtualOps"></v-select>
        <!-- <v-select2 label="组织标签" v-model="searchParams.orgTagId" v-bind="orgParams"></v-select2> -->
        <v-datepicker label="创建时间段" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <div style="width: 120px">
          <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
          <!-- <v-button text="防疫配置" type="text" permission="emergencySettings" @click="showConfigDialog(scope.row)"></v-button>
          <v-button text="项目二维码" type="text" permission="update" @click="showProjCodeDialog(scope.row)"></v-button>
          <v-button text="人员类型配置" type="text" permission="emergencySettings" @click="personConfig(scope.row)"></v-button>
          <v-button text="拓展数据" type="text" @click="improveData(scope.row)"></v-button> -->
          <el-dropdown trigger="click" @command="moreHandle" v-if="emergencySettings||update||improveData">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="emergencySettings" :command="{ row: scope.row, type: 2 }">
                <span>防疫配置</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="update" :command="{ row: scope.row, type: 3 }">
                <span>项目二维码</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="emergencySettings" :command="{ row: scope.row, type: 4 }">
                <span>人员类型配置</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="improveData" :command="{ row: scope.row, type: 5 }">
                <span>扩展数据</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量可用" permission="batch" @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
    <el-dialog title="下载二维码" width="600px" :visible.sync="qrCodeConfig" @closed="qrCodeClosed">
      <div class="qr-wrapper">
        <h1 class="qr-title">项目: {{qrCodeObj.communityName}}</h1>
        <!-- <p>url地址：<a :href="qrCodeUrl" target="_blank">{{qrCodeUrl}}</a></p> -->
        <div class="qrcode-img-wrapper">
          <!-- <div v-if="qrCodeId"
               :id="qrCodeId"
               class="img"></div> -->
          <img :id="qrCodeId" :src="'data:image/png;base64,' + QrCodeImage" alt="">
        </div>
        <v-button text="保存到本地" type="success" @click="downloadQRCode"></v-button>
      </div>

      <!-- <div class="config-btns-wrapper">
        <v-button text="取消" type="default" class="my-cancel-btn" @click="dialogConfig=false"></v-button>
        <v-button text="保存" type="primary" @click="saveConfigData"></v-button>
      </div> -->
    </el-dialog>
    <el-dialog title="防疫配置" width="600px" :visible.sync="dialogConfig" @closed="dialogClosed">
      <div class="config-wrapper">
        <div class="tips">此功能用于配置园区防疫管理方案的严格程度，主要指复工通行码是否可用的判断条件</div>
        <div class="config-content">
          <div class="content-item">
            <div class="title">通行校验</div>
            <div class="item">
              <v-checkbox
                        type="default"
                        label="员工需在24小时内填报无异常的健康登记"
                        v-model="isHealthyRequired"
                        >
                      </v-checkbox>
            </div>
            <div class="item">
              <v-checkbox
                        type="default"
                        label="通行码需校验支付宝健康码的状态"
                        v-model="healthCodeConfig"
                        >
                      </v-checkbox>
            </div>
            <div class="item" v-show="healthCodeConfig">
              <span>选择健康码系统内对应的项目名称：</span>
              <v-select2 v-if="dialogConfig" style="display:inline-block;" :width="200" placeholder="请输入项目名称" v-model="hzfcXmCode" v-bind="healthCodeCommunityParams" />
              <div>
                <i class="el-icon-warning" style="color:red;"></i>
                <span style="color:#999;">若列表里没有您所在的项目，请联系管理员</span>
              </div>
            </div>
            <div class="item">
              <v-checkbox
                        type="default"
                        label="需审核企业复工状态"
                        v-model="userOrgRequired"
                        >
                      </v-checkbox>
            </div>
          </div>
          <div class="content-item">
            <div class="title">员工信息</div>
            <div class="item">
              <v-checkbox
                        type="default"
                        label="须上传身份证正反面"
                        v-model="isCardImgRequired"
                        >
                      </v-checkbox>
            </div>
            <div class="item">
              <v-checkbox
                        type="default"
                        label="须上传正面肖像照（无口罩、有口罩）"
                        v-model="isHealthImgRequired"
                        >
                      </v-checkbox>
            </div>
          </div>
          <div class="content-item">
            <div class="title">访客登记填写信息配置</div>
            <div class="item item-radio">
              <span class="label">拜访企业：</span>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="2" label="必填" v-model="visitorOrgConfig"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="1" label="选填" v-model="visitorOrgConfig"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="0" label="隐藏" v-model="visitorOrgConfig"></v-checkbox>
            </div>
            <div class="item item-radio">
              <span class="label">拜访人：</span>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="2" label="必填" v-model="visitorOwnerConfig"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="1" label="选填" v-model="visitorOwnerConfig"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="0" label="隐藏" v-model="visitorOwnerConfig"></v-checkbox>
            </div>
            <div class="item item-radio">
              <span class="label">访客类型：</span>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="2" label="必填" v-model="visitorTypeConfig"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="1" label="选填" v-model="visitorTypeConfig"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="0" label="隐藏" v-model="visitorTypeConfig"></v-checkbox>
            </div>
            <div class="item item-radio">
              <span class="label">身份证件上传：</span>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="2" label="必填" v-model="visitorCardImgConfig"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="1" label="选填" v-model="visitorCardImgConfig"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="0" label="隐藏" v-model="visitorCardImgConfig"></v-checkbox>
            </div>
            <div class="item item-radio">
              <span class="label">访客是否需要审核：</span>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="2" label="被访者审核" v-model="visitorApproveRequired"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="1" label="项目管理员审核" v-model="visitorApproveRequired"></v-checkbox>
              <v-checkbox class="radio-checkbox" type="radio" :labelValue="0" label="否" v-model="visitorApproveRequired"></v-checkbox>
            </div>
            <div class="item">
              <span class="labe">访客通行次数:</span>
              <v-input-number class="input-number" v-model="visitorEnterCountConfig" :controls="true" :max="9" controls-position="right" />
              <span>（0表示不限次数）</span>
            </div>
          </div>
        </div>
        <div class="config-btns-wrapper">
          <v-button text="取消" type="default" class="my-cancel-btn" @click="dialogConfig=false"></v-button>
          <v-button text="保存" type="primary" @click="saveConfigData"></v-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getProjectTypesURL,
  getRoomTypesURL,
  getCommunitySettings,
  setCommunitySettings,
  getMiniQrcode,
  exportImproveDataURL,
  exportImproveDataURL2,
  exportImproveDataURL3,
  exportImproveDataURL4,
  exportImproveDataURL5,
  exportImproveDataURL6,
  importCommunityInfoBaseUrl,
  importCommunityInfoFinanceUrl,
  importCommunityInfoOwnerUrl,
  importCommunityInfoAreaUrl,
  importCommunityInfoEquipmentUrl,
  importCommunityInfoParkUrl,
} from './api'
import {
  communityStatusOps,
  communityStatusMap,
  virtualOps,
  virtualMap,
  manageAttributeOps,
  // manageAttributeMap,
  serviceAttributeOps
  // serviceAttributeMap
} from './map'
import { vCheckbox } from 'components/control'
import { downloadHelper, _localStorage } from 'common/utils'
import { tenantParams } from 'common/select2Params'
import QRCode from 'qrcodejs2'
export default {
  name: 'ProjectConfigList',
  components: {
    vCheckbox
  },
  data () {
    // let _this = this
    return {
      qrCodeId: '',
      qrCodeUrl: '',
      qrCodeConfig: false,
      communityId: undefined,
      communityName: '',
      isHealthyRequired: false,
      healthCodeConfig: false,
      userOrgRequired: false,
      isCardImgRequired: false,
      hzfcXmCode: '',
      tenantParams,
      healthCodeCommunityParams: {
        searchUrl: `${API_CONFIG.butlerBaseURL}emergency/getHealthCodeXmInfoList/select2`,
        method: 'post',
        contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        request: {
          text: 'xmName',
          value: 'xmcode'
        },
        response: {
          text: 'xmmc',
          value: 'xmcode'
        }
      },
      visitorTypeConfig: 2,
      visitorOrgConfig: 2,
      visitorOwnerConfig: 2,
      visitorApproveRequired: 0,
      visitorCardImgConfig: 2,
      visitorEnterCountConfig: 1,
      isHealthImgRequired: false,
      dialogConfig: false,
      searchUrl: getListURL,
      exportUrl: exportListURL,
      communityStatusOps,
      virtualOps,
      manageAttributeOps,
      serviceAttributeOps,
      communityStageOps: [],
      communityTypeOps: [],
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '开启',
          value: 1
        }, {
          text: '关闭',
          value: 0
        }
      ],
      orgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'tagName',
          value: 'id'
        },
        response: {
          text: 'tagName',
          value: 'id'
        }
      },
      searchParams: {
        communityName: '',
        regionId: undefined,
        province: '',
        city: '',
        area: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0,
        virtual: undefined,
        startTime: '',
        endTime: '',
        street: '',
        shequ: ''
      },
      headers: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态',
          formatter (row) {
            return communityStatusMap[row.communityStatus]
          }
        },
        {
          prop: 'virtualText',
          label: '该项目是否用于测试或展示',
          formatter (row) {
            return virtualMap[row.virtual]
          }
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        // {
        //   prop: 'orgTagNameNew',
        //   label: '关联组织标签',
        //   formatter (row) {
        //     let h = _this.$createElement
        //     return h('span', {
        //       domProps: {
        //         innerHTML: row.orgTagName
        //       },
        //       style: {
        //         color: '#1b8cf2',
        //         cursor: 'pointer',
        //         display: 'inline-block',
        //         width: '100%',
        //         overflow: 'hidden',
        //         textOverflow: 'ellipsis',
        //         whiteSpace: 'nowrap'
        //       },
        //       on: {
        //         click: () => {
        //           _this.$alert(row.orgTagName, {
        //             title: '关联组织标签',
        //             closeOnClickModal: true,
        //             showConfirmButton: false,
        //             showClose: true
        //           })
        //         }
        //       }
        //     })
        //   }
        // },
        // {
        //   prop: 'manageAttributeText',
        //   label: '管理属性',
        //   formatter (row) {
        //     return manageAttributeMap[row.manageAttribute]
        //   }
        // },
        // {
        //   prop: 'serviceAttributeText',
        //   label: '业务属性',
        //   formatter (row) {
        //     return serviceAttributeMap[row.serviceAttribute]
        //   }
        // },
        {
          prop: 'passUserCount',
          label: '通过审核用户数',
          align: 'right'
        },
        {
          prop: 'legalUnitName',
          label: '收款单位'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'province',
          label: '所在省'
        },
        {
          prop: 'city',
          label: '所在市'
        },
        {
          prop: 'area',
          label: '所在区'
        },
        {
          prop: 'street',
          label: '所在街道'
        },
        {
          prop: 'shequ',
          label: '所在社区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      qrCodeObj: {
        name: '',
        code: '',
        address: ''
      },
      qrCodeTitle: '',
      QrCodeImage: '',
      multiExport: [
        {
          text: '项目数据',
          url: exportListURL,
          permission: 'export'
        },
        {
          text: '扩展数据-基础信息',
          url: exportImproveDataURL,
          permission: 'exportImproveData'
        },
        {
          text: '扩展数据-面积信息',
          url: exportImproveDataURL2,
          permission: 'exportImproveData'
        },
        {
          text: '扩展数据-业户信息',
          url: exportImproveDataURL3,
          permission: 'exportImproveData'
        },
        {
          text: '扩展数据-车位信息',
          url: exportImproveDataURL4,
          permission: 'exportImproveData'
        },{
          text: '扩展数据-设备信息',
          url: exportImproveDataURL5,
          permission: 'exportImproveData'
        },
        {
          text: '扩展数据-财务信息',
          url: exportImproveDataURL6,
          permission: 'exportImproveData'
        }
      ],
      multiImport: [
        {
          text: '基础信息',
          downloadUrl: '/static/excel/导入模板（基础信息）.xlsx',
          uploadUrl: importCommunityInfoBaseUrl
        },
        {
          text: '面积信息',
          downloadUrl: '/static/excel/导入模板（面积信息）.xlsx',
          uploadUrl: importCommunityInfoAreaUrl
        },
        {
          text: '业户信息',
          downloadUrl: '/static/excel/导入模板（业户信息）.xlsx',
          uploadUrl: importCommunityInfoOwnerUrl
        },
        {
          text: '车位信息',
          downloadUrl: '/static/excel/导入模板（车位信息）.xlsx',
          uploadUrl: importCommunityInfoParkUrl
        },{
          text: '设备信息',
          downloadUrl: '/static/excel/导入模板（设备信息）.xlsx',
          uploadUrl: importCommunityInfoEquipmentUrl
        },
        {
          text: '财务信息',
          downloadUrl: '/static/excel/导入模板（财务信息）.xlsx',
          uploadUrl: importCommunityInfoFinanceUrl
        }
      ]
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    },
    update () {
      return this.$store.getters.getPermission('update')
    },
    emergencySettings () {
      return this.$store.getters.getPermission('emergencySettings')
    },
    improveData () {
      return this.$store.getters.getPermission('improveData')
    },
    importImproveData () {
      return this.$store.getters.getPermission('importImproveData')
    }
  },
  mounted () {
    this.getProjectTypes()
    this.getRoomTypes()
  },
  methods: {
    handleData (data, index) {
      if (data.id === undefined) {
        data.id = data.communityId
      }
    },
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [{ text: '全部', value: undefined }, ...ops]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [{ text: '全部', value: undefined }, ...ops]
        }
      })
    },
    updateStatus (ids, batchURL) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        ids: _ids,
        dataObject: _ids
      }
      _this.$axios.post(batchURL, postData).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    batch (selected) {
      this.updateStatus(selected.ids, batchURL)
    },
    create () {
      this.$router.push({
        name: 'projectConfigForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'projectConfigForm',
        query: {
          id: row.id
        }
      })
    },
    dialogClosed () {
      this.hzfcXmCode = ''
    },
    // 关闭二维码
    qrCodeClosed () {
      this.qrCodeId = ''
    },
    // 人员类型配置
    personConfig (row) {
      this.$router.push({
        name: 'projectTypeConfig',
        query: {
          id: row.id,
          name: row.communityName
        }
      })
    },
    showConfigDialog (row) {
      this.dialogConfig = true
      this.communityId = row.id
      this.communityName = row.communityName
      this.$axios.get(`${getCommunitySettings}?communityId=${row.id}`).then(res => {
        if (res.status === 100) {
          let { data } = res
          this.isHealthyRequired = (data.isHealthyRequired === 1)
          this.healthCodeConfig = (data.healthCodeConfig === 1)
          this.visitorApproveRequired = data.visitorApproveRequired
          this.visitorEnterCountConfig = data.visitorEnterCountConfig
          this.userOrgRequired = (data.userOrgRequired === 1)
          this.isCardImgRequired = (data.isCardImgRequired === 1)
          this.isHealthImgRequired = (data.isHealthImgRequired === 1)
          this.visitorOrgConfig = data.visitorOrgConfig
          this.visitorOwnerConfig = data.visitorOwnerConfig
          this.visitorTypeConfig = data.visitorTypeConfig
          this.visitorCardImgConfig = data.visitorCardImgConfig
          if (data.healthCodeConfig === 1) {
            this.hzfcXmCode = data.hzfcXmCode
          }
        }
      })
    },
    // 项目二维码
    showProjCodeDialog (row) {
      this.qrCodeTitle = row.communityName
      // this.createQrcode(row)
      this.handleGetMiniQrcode(row)
    },
		createQrcode (row) {
      let companyCode = _localStorage.getItem('companyCode')
      let data = `${API_CONFIG.h5BaseURL}h5/community/tmpPark/index.html?communityId=${row.communityId}&companyCode=${companyCode}#/index`
      this.qrCodeObj = row
      this.qrCodeUrl = data
      this.qrCodeConfig = true
      this.qrCodeId = `img_${data}`
      this.$nextTick(() => {
        new QRCode(this.qrCodeId, { // eslint-disable-line
          width: 200,
          height: 200,
          text: data
        })
      }, 20)
    },
    async handleGetMiniQrcode (row) {
      const res =  await this.$axios.get(`${getMiniQrcode}?communityId=${row.communityId}`)
      console.log(res);
      if (res.status === 100) {
        this.qrCodeConfig = true
        this.qrCodeObj = row
        this.qrCodeId = `img_${res.data}`
        this.QrCodeImage = res.data;
      }
    },
    // 保存二维码到本地
    downloadQRCode () {
      // downloadHelper
      let qrcodeEle = document.getElementById(this.qrCodeId)
      let url = qrcodeEle.src
      downloadHelper.downloadBySrc({
        src: url,
        fileName: this.qrCodeObj.communityName
      })
      this.dialogQrcode = false
    },
    async saveConfigData () {
      if (this.healthCodeConfig && !this.hzfcXmCode) {
        this.$message.error('请先选择项目')
        return
      }
      let communityId = this.communityId
      const { status } = await this.$axios({
        method: 'post',
        params: {
          communityId,
          dataObject: this.communityName,
          isHealthyRequired: this.isHealthyRequired ? 1 : 0,
          healthCodeConfig: this.healthCodeConfig ? 1 : 0,
          hzfcXmCode: this.healthCodeConfig ? this.hzfcXmCode : '',
          visitorApproveRequired: this.visitorApproveRequired,
          visitorEnterCountConfig: this.visitorEnterCountConfig,
          userOrgRequired: this.userOrgRequired ? 1 : 0,
          isCardImgRequired: this.isCardImgRequired ? 1 : 0,
          isHealthImgRequired: this.isHealthImgRequired ? 1 : 0,
          visitorOrgConfig: this.visitorOrgConfig,
          visitorOwnerConfig: this.visitorOwnerConfig,
          visitorTypeConfig: this.visitorTypeConfig,
          visitorCardImgConfig: this.visitorCardImgConfig
        },
        // headers: {
        //   'Content-type': 'application/x-www-form-urlencoded'
        // },
        url: setCommunitySettings
      })
      if (status === 100) {
        this.$message.success('保存成功')
        this.dialogConfig = false
      }
    },
    goPageImport () {
      this.$router.push({
        name: 'projectConfigImport'
      })
    },
    goPageImproveDataImport () {
      this.$router.push({
        name: 'improveDataImport'
      })
    },
    moreHandle (val) {

      console.log(val)
      let _this = this
      if (val.type === 2) {
         console.log('333')
        _this.showConfigDialog(val.row)
      } else if (val.type === 3) {
        _this.showProjCodeDialog(val.row)
      } else if (val.type === 4) {
        _this.personConfig(val.row)
      } else if (val.type === 5) {
        _this.goImproveData(val.row)
      }
    },
    goImproveData (row) {
      this.$router.push({
        name: 'ImproveDataConfigForm',
        query: {
          id: row.id
        }
      })
    },
    importClick(val) {
      console.log(val,'hhhh')
      this.$router.push({
        name: 'improveDataImport',
        query: {
          text: val.text,
          downloadUrl: val.downloadUrl,
          uploadUrl: val.uploadUrl
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.qr-wrapper {
  .qr-title {
    padding-bottom: 20px;
    border-bottom: 1px dashed #000;
  }
  .qrcode-img-wrapper {
    ::v-deep img {
      margin: 0 auto !important;
    }
    img {
      width: 300px;
      height: 300px;
    }
    margin-bottom: 20px;
  }
}
.config-wrapper{
  text-align: left;
  .tips {
    margin-bottom: 20px;
  }
  .config-content{
    margin-bottom: 20px;
    .title {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .content-item{
      margin-bottom: 20px;
    }
    .item{
      margin-bottom: 5px;
    }
    .item-radio {
      display: flex;
      align-items: center;
      .label {
        margin-right: 10px;
      }
      .radio-checkbox {
        margin-right: 10px;
      }
    }
    .input-number {
      padding-left: 10px;
    }
  }
  .config-btns-wrapper{
    display: flex;
    justify-content: center;
    .my-cancel-btn{
      margin-right: 20px;
    }
  }
}
::v-deep .el-dropdown-selfdefine{
  margin-right: 0px !important;
}
</style>
