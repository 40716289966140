var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "projectConfigList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "",
          searchUrl: _vm.searchUrl,
          multiExport: _vm.multiExport,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          handleData: _vm.handleData,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _vm.importImproveData
                  ? _c(
                      "el-dropdown",
                      {
                        attrs: { trigger: "click" },
                        on: { command: _vm.importClick },
                      },
                      [
                        _c("v-button", [
                          _vm._v("\n          导入扩展数据"),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          _vm._l(_vm.multiImport, function (item, index) {
                            return _c(
                              "el-dropdown-item",
                              { key: index, attrs: { command: item } },
                              [
                                _c("v-button", {
                                  attrs: { type: "text", text: item.text },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.userType !== "101"
                  ? _c("v-button", {
                      attrs: { text: "导入项目", permission: "import" },
                      on: { click: _vm.goPageImport },
                    })
                  : _vm._e(),
                _vm.userType !== "101"
                  ? _c("v-button", {
                      attrs: { text: "新增项目", permission: "add" },
                      on: { click: _vm.create },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.tenantParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "所在省" },
                  model: {
                    value: _vm.searchParams.province,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "province", $$v)
                    },
                    expression: "searchParams.province",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在城市" },
                  model: {
                    value: _vm.searchParams.city,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "city", $$v)
                    },
                    expression: "searchParams.city",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在区" },
                  model: {
                    value: _vm.searchParams.area,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "area", $$v)
                    },
                    expression: "searchParams.area",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在街道" },
                  model: {
                    value: _vm.searchParams.street,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "street", $$v)
                    },
                    expression: "searchParams.street",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在社区" },
                  model: {
                    value: _vm.searchParams.shequ,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "shequ", $$v)
                    },
                    expression: "searchParams.shequ",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "项目阶段状态",
                    options: _vm.communityStageOps,
                  },
                  model: {
                    value: _vm.searchParams.communityStage,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStage", $$v)
                    },
                    expression: "searchParams.communityStage",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "项目类型", options: _vm.communityTypeOps },
                  model: {
                    value: _vm.searchParams.communityType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityType", $$v)
                    },
                    expression: "searchParams.communityType",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "项目状态", options: _vm.communityStatusOps },
                  model: {
                    value: _vm.searchParams.communityStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStatus", $$v)
                    },
                    expression: "searchParams.communityStatus",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "该项目是否用于测试或展示",
                    options: _vm.virtualOps,
                  },
                  model: {
                    value: _vm.searchParams.virtual,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "virtual", $$v)
                    },
                    expression: "searchParams.virtual",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间段",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  { staticStyle: { width: "120px" } },
                  [
                    _c("v-button", {
                      attrs: {
                        text: "编辑",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    }),
                    _vm.emergencySettings || _vm.update || _vm.improveData
                      ? _c(
                          "el-dropdown",
                          {
                            attrs: { trigger: "click" },
                            on: { command: _vm.moreHandle },
                          },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n            更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.emergencySettings
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: { row: scope.row, type: 2 },
                                        },
                                      },
                                      [_c("span", [_vm._v("防疫配置")])]
                                    )
                                  : _vm._e(),
                                _vm.update
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: { row: scope.row, type: 3 },
                                        },
                                      },
                                      [_c("span", [_vm._v("项目二维码")])]
                                    )
                                  : _vm._e(),
                                _vm.emergencySettings
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: { row: scope.row, type: 4 },
                                        },
                                      },
                                      [_c("span", [_vm._v("人员类型配置")])]
                                    )
                                  : _vm._e(),
                                _vm.improveData
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: { row: scope.row, type: 5 },
                                        },
                                      },
                                      [_c("span", [_vm._v("扩展数据")])]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "批量可用", permission: "batch" },
                  on: {
                    click: function ($event) {
                      return _vm.batch(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "下载二维码",
            width: "600px",
            visible: _vm.qrCodeConfig,
          },
          on: {
            "update:visible": function ($event) {
              _vm.qrCodeConfig = $event
            },
            closed: _vm.qrCodeClosed,
          },
        },
        [
          _c(
            "div",
            { staticClass: "qr-wrapper" },
            [
              _c("h1", { staticClass: "qr-title" }, [
                _vm._v("项目: " + _vm._s(_vm.qrCodeObj.communityName)),
              ]),
              _c("div", { staticClass: "qrcode-img-wrapper" }, [
                _c("img", {
                  attrs: {
                    id: _vm.qrCodeId,
                    src: "data:image/png;base64," + _vm.QrCodeImage,
                    alt: "",
                  },
                }),
              ]),
              _c("v-button", {
                attrs: { text: "保存到本地", type: "success" },
                on: { click: _vm.downloadQRCode },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "防疫配置",
            width: "600px",
            visible: _vm.dialogConfig,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogConfig = $event
            },
            closed: _vm.dialogClosed,
          },
        },
        [
          _c("div", { staticClass: "config-wrapper" }, [
            _c("div", { staticClass: "tips" }, [
              _vm._v(
                "此功能用于配置园区防疫管理方案的严格程度，主要指复工通行码是否可用的判断条件"
              ),
            ]),
            _c("div", { staticClass: "config-content" }, [
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("通行校验")]),
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        type: "default",
                        label: "员工需在24小时内填报无异常的健康登记",
                      },
                      model: {
                        value: _vm.isHealthyRequired,
                        callback: function ($$v) {
                          _vm.isHealthyRequired = $$v
                        },
                        expression: "isHealthyRequired",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        type: "default",
                        label: "通行码需校验支付宝健康码的状态",
                      },
                      model: {
                        value: _vm.healthCodeConfig,
                        callback: function ($$v) {
                          _vm.healthCodeConfig = $$v
                        },
                        expression: "healthCodeConfig",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.healthCodeConfig,
                        expression: "healthCodeConfig",
                      },
                    ],
                    staticClass: "item",
                  },
                  [
                    _c("span", [_vm._v("选择健康码系统内对应的项目名称：")]),
                    _vm.dialogConfig
                      ? _c(
                          "v-select2",
                          _vm._b(
                            {
                              staticStyle: { display: "inline-block" },
                              attrs: {
                                width: 200,
                                placeholder: "请输入项目名称",
                              },
                              model: {
                                value: _vm.hzfcXmCode,
                                callback: function ($$v) {
                                  _vm.hzfcXmCode = $$v
                                },
                                expression: "hzfcXmCode",
                              },
                            },
                            "v-select2",
                            _vm.healthCodeCommunityParams,
                            false
                          )
                        )
                      : _vm._e(),
                    _c("div", [
                      _c("i", {
                        staticClass: "el-icon-warning",
                        staticStyle: { color: "red" },
                      }),
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v("若列表里没有您所在的项目，请联系管理员"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("v-checkbox", {
                      attrs: { type: "default", label: "需审核企业复工状态" },
                      model: {
                        value: _vm.userOrgRequired,
                        callback: function ($$v) {
                          _vm.userOrgRequired = $$v
                        },
                        expression: "userOrgRequired",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("员工信息")]),
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("v-checkbox", {
                      attrs: { type: "default", label: "须上传身份证正反面" },
                      model: {
                        value: _vm.isCardImgRequired,
                        callback: function ($$v) {
                          _vm.isCardImgRequired = $$v
                        },
                        expression: "isCardImgRequired",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        type: "default",
                        label: "须上传正面肖像照（无口罩、有口罩）",
                      },
                      model: {
                        value: _vm.isHealthImgRequired,
                        callback: function ($$v) {
                          _vm.isHealthImgRequired = $$v
                        },
                        expression: "isHealthImgRequired",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("访客登记填写信息配置"),
                ]),
                _c(
                  "div",
                  { staticClass: "item item-radio" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("拜访企业："),
                    ]),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 2, label: "必填" },
                      model: {
                        value: _vm.visitorOrgConfig,
                        callback: function ($$v) {
                          _vm.visitorOrgConfig = $$v
                        },
                        expression: "visitorOrgConfig",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 1, label: "选填" },
                      model: {
                        value: _vm.visitorOrgConfig,
                        callback: function ($$v) {
                          _vm.visitorOrgConfig = $$v
                        },
                        expression: "visitorOrgConfig",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 0, label: "隐藏" },
                      model: {
                        value: _vm.visitorOrgConfig,
                        callback: function ($$v) {
                          _vm.visitorOrgConfig = $$v
                        },
                        expression: "visitorOrgConfig",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item item-radio" },
                  [
                    _c("span", { staticClass: "label" }, [_vm._v("拜访人：")]),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 2, label: "必填" },
                      model: {
                        value: _vm.visitorOwnerConfig,
                        callback: function ($$v) {
                          _vm.visitorOwnerConfig = $$v
                        },
                        expression: "visitorOwnerConfig",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 1, label: "选填" },
                      model: {
                        value: _vm.visitorOwnerConfig,
                        callback: function ($$v) {
                          _vm.visitorOwnerConfig = $$v
                        },
                        expression: "visitorOwnerConfig",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 0, label: "隐藏" },
                      model: {
                        value: _vm.visitorOwnerConfig,
                        callback: function ($$v) {
                          _vm.visitorOwnerConfig = $$v
                        },
                        expression: "visitorOwnerConfig",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item item-radio" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("访客类型："),
                    ]),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 2, label: "必填" },
                      model: {
                        value: _vm.visitorTypeConfig,
                        callback: function ($$v) {
                          _vm.visitorTypeConfig = $$v
                        },
                        expression: "visitorTypeConfig",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 1, label: "选填" },
                      model: {
                        value: _vm.visitorTypeConfig,
                        callback: function ($$v) {
                          _vm.visitorTypeConfig = $$v
                        },
                        expression: "visitorTypeConfig",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 0, label: "隐藏" },
                      model: {
                        value: _vm.visitorTypeConfig,
                        callback: function ($$v) {
                          _vm.visitorTypeConfig = $$v
                        },
                        expression: "visitorTypeConfig",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item item-radio" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("身份证件上传："),
                    ]),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 2, label: "必填" },
                      model: {
                        value: _vm.visitorCardImgConfig,
                        callback: function ($$v) {
                          _vm.visitorCardImgConfig = $$v
                        },
                        expression: "visitorCardImgConfig",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 1, label: "选填" },
                      model: {
                        value: _vm.visitorCardImgConfig,
                        callback: function ($$v) {
                          _vm.visitorCardImgConfig = $$v
                        },
                        expression: "visitorCardImgConfig",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 0, label: "隐藏" },
                      model: {
                        value: _vm.visitorCardImgConfig,
                        callback: function ($$v) {
                          _vm.visitorCardImgConfig = $$v
                        },
                        expression: "visitorCardImgConfig",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item item-radio" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("访客是否需要审核："),
                    ]),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: {
                        type: "radio",
                        labelValue: 2,
                        label: "被访者审核",
                      },
                      model: {
                        value: _vm.visitorApproveRequired,
                        callback: function ($$v) {
                          _vm.visitorApproveRequired = $$v
                        },
                        expression: "visitorApproveRequired",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: {
                        type: "radio",
                        labelValue: 1,
                        label: "项目管理员审核",
                      },
                      model: {
                        value: _vm.visitorApproveRequired,
                        callback: function ($$v) {
                          _vm.visitorApproveRequired = $$v
                        },
                        expression: "visitorApproveRequired",
                      },
                    }),
                    _c("v-checkbox", {
                      staticClass: "radio-checkbox",
                      attrs: { type: "radio", labelValue: 0, label: "否" },
                      model: {
                        value: _vm.visitorApproveRequired,
                        callback: function ($$v) {
                          _vm.visitorApproveRequired = $$v
                        },
                        expression: "visitorApproveRequired",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", { staticClass: "labe" }, [
                      _vm._v("访客通行次数:"),
                    ]),
                    _c("v-input-number", {
                      staticClass: "input-number",
                      attrs: {
                        controls: true,
                        max: 9,
                        "controls-position": "right",
                      },
                      model: {
                        value: _vm.visitorEnterCountConfig,
                        callback: function ($$v) {
                          _vm.visitorEnterCountConfig = $$v
                        },
                        expression: "visitorEnterCountConfig",
                      },
                    }),
                    _c("span", [_vm._v("（0表示不限次数）")]),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "config-btns-wrapper" },
              [
                _c("v-button", {
                  staticClass: "my-cancel-btn",
                  attrs: { text: "取消", type: "default" },
                  on: {
                    click: function ($event) {
                      _vm.dialogConfig = false
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "保存", type: "primary" },
                  on: { click: _vm.saveConfigData },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }